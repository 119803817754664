<template>
  <div class="editor">
    <div class="form-item" v-if="isDay===0">
      <span class="vam m-r">{{$t('Days')}}：</span>
      <input class="ipt m-r vam" type="number" autocomplete="off" v-model.number="days"/>
      <span class="vam m-r">{{$t('Day')}}</span>
      <input class="ipt m-r vam" type="number" autocomplete="off" v-model.number="lates"/>
      <span class="vam">{{$t('Night')}}</span>
    </div>
<!--    <froala :config="froalaConfig" v-model="content"/>-->
<!--    <div  id="editor"></div>-->
    <editorElem v-model="content"/>
    <div class="actions">
      <button class="button button-primary" type="button" :disabled="!content" @click="onSubmit">{{$t('Save')}}</button>
      <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import editor from '../../../../mixins/editor';
  import {getProductLineIntroduction, saveProductLineIntroduction, adminGetProduct} from '../../../../api/admin-product';
  import editorElem from '../../../../components/EditorBar';
  export default {
    name: 'ProductDescription',
    props: {
      productId: String,
      language: String,
      lineId: String
    },
    data() {
      return {
        days: '',
        lates: '',
        content: '',
        oldDays: '',
        oldLates: '',
        oldContent: '',
        isDay: '',
        editor: ''
      };
    },
    components: {editorElem},
    mixins: [editor],
    created() {
      this.getProductLineIntroduction();
      this.adminGetProduct();
    },
    destroyed() {
      if (this.content !== this.oldContent || this.days !== this.oldDays || this.lates !== this.oldLates) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      // 获取产品介绍
      getProductLineIntroduction() {
        getProductLineIntroduction(this.language, this.lineId).then(res => {
          const content = (res.value || {}).introduction || '';
          const days = (res.value || {}).days || '';
          const lates = (res.value || {}).lates || '';
          this.content = content;

          this.days = days;
          this.lates = lates;
          this.oldContent = content;
          this.oldDays = days;
          this.oldLates = lates;
        });
      },
      adminGetProduct() {
        adminGetProduct(this.productId).then(res => {
          this.isDay = res.value.isDay;
        });
      },
      // 存储
      onSubmit(callback, isForcedSave) {
        if (this.isDay) {
          this.days = 1;
          this.lates = 0;
        }
        const data = {
          productId: this.productId,
          languageType: this.language,
          lineId: this.lineId,
          introduction: this.content,
          days: this.days,
          lates: this.lates
        };
        if (!data.days && !data.lates) {
          this.$message.warning(this.$t('Please fill out', [this.$t('Days')]));
          return false;
        }
        saveProductLineIntroduction(data).then(res => {
          this.oldContent = this.content;
          this.oldDays = this.days;
          this.oldLates = this.lates;
          !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
          if (typeof callback === 'function') {
            callback();
          }
        });
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      }
    },
    watch: {
      lineId: function (value) {
        this.getProductLineIntroduction();
      }
    }
  };
</script>
<style lang="less" scoped>
  .form-item{
    .ipt{
      width:80px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .m-r{margin-right:8px;}
    margin-bottom:24px;
  }
</style>
