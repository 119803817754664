<template>
  <modal :title="$t('AddPrice')" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="add-price">
      <tabs v-model="activeName">
        <tab-pane :label="$t('ByWeek')" name="ByWeek">
          <add-price-by-week :product-id="productId"
                             :line-id="lineId"
                             :price-dates="priceDates"
                             @close="hide"
                             @refresh-price="refreshPriceList"/>
        </tab-pane>
        <tab-pane :label="$t('ByDate')" name="ByDate">
          <add-price-by-date :product-id="productId"
                             :line-id="lineId"
                             :price-dates="priceDates"
                             @close="hide"
                             @refresh-price="refreshPriceList"
                             :status="priceDateStatus"/>
        </tab-pane>
      </tabs>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../../../components/Modal';
  import Tabs from '../../../../components/Tabs/Tabs';
  import TabPane from '../../../../components/Tabs/TabPane';
  import AddPriceByWeek from './AddPriceByWeek';
  import AddPriceByDate from './AddPriceByDate';

  export default {
    name: 'AddPriceModal',
    props: {
      productId: String,
      lineId: String,
      priceDates: Array,
      priceDateStatus: Object
    },
    data() {
      return {
        isShow: false,
        activeName: 'ByWeek'
      };
    },
    components: {Modal, Tabs, TabPane, AddPriceByWeek, AddPriceByDate},
    created() {
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      refreshPriceList() {
        this.$emit('refresh-price');
      }
    }
  };
</script>
<style scoped lang="less">
  .add-price{
    padding:0 30px 40px;
    /deep/ .tab-nav{margin-top:16px;text-align:left;}
  }
</style>
