<template>
  <div class="picture-item">
    <div class="hd cl">
      <a class="btn-del" href="javascript:;" @click="deletePicture" v-if="showDeleteButton">
        <img src="../../../../assets/images/icon/ashcan.png"/>
      </a>
      <checkbox class="fr" :value="item.mediaId === bigPictureId" @change="toggleCheckbox">
        <div class="checkbox-label">{{$t('DefaultBigPicture')}}</div>
      </checkbox>
    </div>
    <div class="bd cl">
      <div class="uploader">
        <el-progress class="upload-progress" type="circle" color="#ff6f61" :percentage="progress" v-if="showProgress"/>
        <el-upload action="/v2/admin/common/file"
                   :show-file-list="false"
                   accept="image/*"
                   :http-request="uploadPicture"
                   v-else>
          <img class="img" v-if="item.photoUrl" :src="item.photoUrl"/>
          <div class="btn-upload" v-else>
            <img src="../../../../assets/images/icon/upload-40.png"/>
            <p class="tip">{{$t('UploadPhotos')}}</p>
          </div>
        </el-upload>
      </div>
      <div class="infos">
        <div>
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('EnterImageTitle')"
                 v-model.trim="item.title"/>
        </div>
        <div class="textarea-wrap">
          <el-input type="textarea"
                    :placeholder="$t('EnterImageIntroduction')"
                    v-model.trim="item.introduction"
                    maxlength="1000">
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from '../../../../components/Checkbox';
import {upload} from '../../../../api/admin-common';

export default {
    name: 'MultimediaItem',
    props: {
      index: Number,
      item: Object,
      showDeleteButton: Boolean,
      bigPictureId: String
    },
    data() {
      return {
        showProgress: false,
        progress: 0
      };
    },
    components: {Checkbox},
    methods: {
      deletePicture() {
        this.$emit('delete-picture', this.index);
      },
      // 覆盖默认的上传行为，可以自定义上传的实现
      uploadPicture(param) {
        let formData = new FormData();
        formData.append('file', param.file);
        this.showProgress = true;
        upload(formData, this.onUploadProgress).then(res => {
          this.item.photoUrl = res.value.url;
          this.showProgress = false;
          this.progress = 0;
        });
      },
      // 上传进度
      onUploadProgress(e) {
        this.progress = Number(((e.loaded / e.total * 100) || 0).toFixed(2));
      },
      // 选中/取消选中默认大图
      toggleCheckbox(value) {
        this.$emit('update-bgid', value ? this.item.mediaId : '');
      }
    }
  };
</script>
<style scoped lang="less">
  .picture-item{
    margin-bottom:16px;padding:0 20px;background-color:#f4f4f4;
    .hd{padding:20px 0;line-height:16px;border-bottom:1px dashed #e0e0e0;}
    .bd{padding:20px 0;}
    .infos{margin-left:310px;}
    .textarea-wrap{margin-top:8px;}
    .ipt{
      width:100%;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .btn-del{float:right;margin-left:16px;}
  }
  .uploader{
    position:relative;float:left;width:300px;height:200px;overflow:hidden;border:1px solid #e6e6e6;background-color:#fff;border-radius:4px;
    .btn-upload{
      width:300px;height:200px;padding-top:60px;text-align:center;
      .tip{margin-top:16px;color:#999;}
    }
    .upload-progress{position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);}
    /deep/ .el-upload{width:300px;height:200px;}
  }
</style>
