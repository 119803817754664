<template>
  <div class="add-price-date">
    <div class="form-item m-t cl">
      <div class="label">{{$t('Currency')}}：</div>
      <input class="fl ipt txt-l" type="text" disabled="disabled" :value="$t('Currencys')[paymentCurrency]"/>
    </div>
    <div class="tip">{{$t('ClickOnTheDepartureDayInTheCalendarToAddPrices')}}</div>
    <calendars :selecteds="priceDates" v-model="selectedList" :status="status"/>
    <div class="selected-list" v-if="selectedList.length">
      <div class="title">{{$t('SelectedDate')}}：</div>
      <div class="cl">
        <div class="item"
             v-for="(item,index) of selectedList"
             :key="item">
          {{item}}
          <a class="btn-del" href="javascript:;" @click="deleteItem(index)">
            <img src="../../../../assets/images/icon/close-circle.png"/>
          </a>
        </div>
      </div>
    </div>
    <price-form :datas.sync="priceForm" ref="priceForm"/>
    <div class="form-item cl m-t">
      <div class="label">{{$t('MaximumNumberOfPeople')}}：</div>
      <div class="fl">
        <input class="ipt" type="number" autocomplete="off" :disabled="isLimitPeople" :placeholder="$t('PleaseEnter')"
               v-model="limitPeopleNumber"/>
      </div>
      <div class="unit">{{$t('People')}}</div>
      <checkbox class="fl" style="margin:8px 0 0 24px" v-model="isLimitPeople">
        <div class="checkbox-label">{{$t('NoUpperLimit')}}</div>
      </checkbox>
    </div>
    <div class="form-item cl m-t">
      <div class="label">{{$t('GroupOfPeople')}}：</div>
      <div class="fl">
        <input class="ipt" type="number" autocomplete="off" :placeholder="$t('PleaseEnter')"
               v-model="groupPeopleNumber"/>
      </div>
      <div class="unit">{{$t('People')}}</div>
    </div>
    <div class="form-item cl m-t">
      <div class="label" style="margin-right:12px">{{$t('After the time: before the group')}}</div>
      <div class="fl">
        <input class="ipt" type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="obtainedDays"/>
      </div>
      <div class="unit">{{$t('Day')}}</div>
    </div>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('SaveAndPublish')}}</button>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Calendars from '../../../../components/Calendars';
  import Checkbox from '../../../../components/Checkbox';
  import PriceForm from './PriceForm';
  import {addProductPriceByDate} from '../../../../api/admin-product';

  export default {
    name: 'AddPriceByDate',
    props: {
      productId: String,
      lineId: String,
      priceDates: Array,
      status: Object
    },
    data() {
      return {
        selectedList: [],
        isLimitPeople: false,
        limitPeopleNumber: '',
        groupPeopleNumber: '',
        obtainedDays: '',
        priceForm: {
          isAdult: 'Y',
          adultPrice: '',
          isChild: 'Y',
          childPrice: '',
          isNotChild: 'Y',
          notChildPrice: '',
          isBaby: 'Y',
          babyPrice: '',
          isSingleBed: 'Y',
          singleBedPrice: '',
          priceType: 0
        }
      };
    },
    computed: {...mapGetters(['paymentCurrency'])},
    components: {PriceForm, Calendars, Checkbox},
    methods: {
      deleteItem(index) {
        this.selectedList.splice(index, 1);
      },
      // 保存并发布
      onSubmit() {
        const data = {
          productId: this.productId,
          lineId: this.lineId,
          priceDates: this.selectedList,
          isLimitPeople: this.isLimitPeople ? 'N' : 'Y',
          limitPeopleNumber: this.limitPeopleNumber,
          groupPeopleNumber: this.groupPeopleNumber,
          obtainedDays: this.obtainedDays
        };
        const priceData = this.$refs.priceForm.getPriceData();

        if (!data.priceDates.length) {
          this.$message.warning(this.$t('Please choose', [this.$t('Date')]));
          return false;
        }
        if (!priceData) {
          return false;
        }

        addProductPriceByDate({...data, ...priceData}).then(res => {
          this.$message.success('發佈成功');
          this.$emit('close');
          this.$emit('refresh-price');
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .add-price-date{
    text-align:left;font-size:16px;
    .m-t{margin-top:24px;}
    .tip{margin-top:30px;margin-bottom:16px;}
  }
  .form-item{
    .label{float:left;line-height:32px;color:#321908;font-size:16px;}
    .ipt{
      width:160px;height:32px;padding:0 10px;text-align:center;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .unit{float:left;margin-left:12px;line-height:32px;}
  }
  .selected-list{
    user-select:none;
    .title{margin-top:30px;padding-bottom:6px;font-size:16px;}
    .item{
      position:relative;float:left;width:130px;margin-top:10px;margin-right:10px;line-height:42px;text-align:center;color:#999;font-size:18px;border:1px dashed #ccc;border-radius:4px;
      &:hover{
        .btn-del{display:block;}
      }
    }
    .btn-del{display:none;position:absolute;top:10px;right:2px;line-height:1;}
  }
</style>
