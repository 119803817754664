<template>
  <div class="upload-schedule">
    <div class="title">{{$t('Please upload your itinerary or promotional documents')}}</div>
    <div class="upload-wrap">
      <el-upload drag
                 action="/v2/admin/common/file"
                 accept=".pdf"
                 multiple
                 :file-list="fileList"
                 :limit="1"
                 :on-exceed="handleExceed"
                 :on-remove="handleRemove"
                 :on-success="handleFileSuccess"
                 :on-preview="handlePreview"
                 ref="uploader">
        <div class="ico">
          <img class="img" src="../../../../assets/images/icon/upload-red-80.png" alt=""/>
        </div>
        <div class="upload-txt">{{$t('Click or drag and drop files to upload here')}}</div>
        <div class="upload-tip">{{$t('Only supports pdf format documents')}}</div>
        <!--        <div slot="file" slot-scope="{file}">-->
        <!--          <li tabindex="0" class="el-upload-list__item is-success">-->
        <!--            <a class="el-upload-list__item-name" target="_blank" :href="fileUrl||file.url">-->
        <!--              <i class="el-icon-document"></i>{{fileName||file.name}}-->
        <!--            </a>-->
        <!--            <label class="el-upload-list__item-status-label">-->
        <!--              <i class="el-icon-upload-success el-icon-circle-check"></i>-->
        <!--            </label>-->
        <!--            <i class="el-icon-close" @click="handleRemove(file)"></i>-->
        <!--          </li>-->
        <!--        </div>-->
      </el-upload>
    </div>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
      <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import {getProductAttachment, saveProductAttachment} from '../../../../api/admin-product';

  export default {
    name: 'UploadSchedule',
    props: {
      productId: String,
      language: String,
      lineId: String
    },
    data() {
      return {
        fileUrl: '',
        fileName: '',
        fileList: [],
        oldFileUrl: '',
        oldFileName: ''
      };
    },
    created() {
      this.getProductAttachment();
    },
    destroyed() {
      if (this.fileUrl !== this.oldFileUrl || this.fileName !== this.oldFileName) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      // 获取产品路线行程附件
      getProductAttachment() {
        getProductAttachment(this.lineId).then(res => {
          const value = res.value;
          if (value && value.fileUrl) {
            const fileUrl = value.fileUrl || '';
            const fileName = value.fileName || '';
            this.fileUrl = fileUrl;
            this.fileName = fileName;
            this.fileList.push({name: fileName, url: fileUrl});
            this.oldFileUrl = this.fileUrl;
            this.oldFileName = this.fileName;
          }
        });
      },
      handleExceed(files, fileList) {
        this.$message.warning('最多只能上传1個檔案');
      },
      handleRemove(file) {
        this.fileUrl = '';
        this.fileName = '';
        this.$refs.uploader.clearFiles();
      },
      handleFileSuccess(res, file, fileList) {
        this.fileUrl = res.value.url;
        this.fileName = res.value.fileName;
      },
      handlePreview(file) {
        if (this.fileUrl) {
          window.open(this.fileUrl, '_blank');
        }
      },
      onSubmit(callback, isForcedSave) {
        const data = {productId: this.productId, lineId: this.lineId, fileUrl: this.fileUrl, fileName: this.fileName};
        if (!this.fileUrl) {
          this.$message.warning('請上載行程表或宣傳文件');
          return false;
        }

        saveProductAttachment(data).then(res => {
          this.oldFileUrl = this.fileUrl;
          this.oldFileName = this.fileName;
          !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
          if (typeof callback === 'function') {
            callback();
          }
        });
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .title{margin-bottom:20px;text-align:center;font-size:16px;}
  .upload-wrap{
    width:452px;margin:0 auto;
    /deep/ .el-upload-dragger{
      width:452px;height:263px;border:1px dashed #979797;border-radius:0;
      .ico{width:80px;height:80px;margin:30px auto 0;}
      .upload-txt{margin-top:24px;color:#ff6f61;font-size:24px;}
      .upload-tip{margin-top:16px;color:#999;}
      &:hover{border-color:#ff6f61;}
    }
  }
</style>
