<template>
  <table class="table" v-if="datas">
    <tr>
      <td class="label txt-l" width="230">
        <el-dropdown trigger="click" @command="changePriceType">
          <a class="link-status" href="javascript:;">
            {{$t('PriceTypes')[datas.priceType]}} <i class="el-icon-caret-bottom"></i>
          </a>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) of $t('PriceTypes')" :command="{index}" :key="item.value">
              {{item}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </td>
      <td class="label" width="200">{{$t('Status')}}</td>
      <td class="label" width="200">{{$t('Sell')}}</td>
    </tr>
    <tr>
      <td class="label txt-l">{{datas.priceType===0?$t('AdultPrice'):$t('Passenger uniform price')}}</td>
      <td>
        <el-dropdown trigger="click" @command="changeStatus">
          <a class="link-status" href="javascript:;">
            {{datas.isAdult|availableStatus}} <i class="el-icon-caret-bottom"></i>
          </a>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item of statusOption"
                              :command="{key:'isAdult',value:item.value}"
                              :key="item.value">{{item.label}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </td>
      <td>
        <input type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="datas.adultPrice"/>
      </td>
    </tr>
    <template v-if="datas.priceType===0">
      <tr>
        <td class="label txt-l">{{$t('ChildPrice')}}</td>
        <td>
          <el-dropdown trigger="click" @command="changeStatus">
            <a class="link-status" href="javascript:;">
              {{datas.isChild|availableStatus}} <i class="el-icon-caret-bottom"></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item of statusOption"
                                :command="{key:'isChild',value:item.value}"
                                :key="item.value">{{item.label}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>
        <td>
          <input type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="datas.childPrice"/>
        </td>
      </tr>
      <tr>
        <td class="label txt-l">{{$t('NotChildPrice')}}</td>
        <td>
          <el-dropdown trigger="click" @command="changeStatus">
            <a class="link-status" href="javascript:;">
              {{datas.isChild|availableStatus}} <i class="el-icon-caret-bottom"></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item of statusOption"
                                :command="{key:'isChild',value:item.value}"
                                :key="item.value">{{item.label}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>
        <td>
          <input type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="datas.notChildPrice"/>
        </td>
      </tr>
      <tr>
        <td class="label txt-l">{{$t('BabyPrice')}}</td>
        <td>
          <el-dropdown trigger="click" @command="changeStatus">
            <a class="link-status" href="javascript:;">
              {{datas.isBaby|availableStatus}} <i class="el-icon-caret-bottom"></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item of statusOption"
                                :command="{key:'isBaby',value:item.value}"
                                :key="item.value">{{item.label}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>
        <td>
          <input type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="datas.babyPrice"/>
        </td>
      </tr>
    </template>
    <tr>
      <td class="label txt-l">{{$t('SingleBedDifference')}}</td>
      <td>
        <el-dropdown trigger="click" @command="changeStatus">
          <a class="link-status" href="javascript:;">
            {{datas.isSingleBed|availableStatus}} <i class="el-icon-caret-bottom"></i>
          </a>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item of statusOption"
                              :command="{key:'isSingleBed',value:item.value}"
                              :key="item.value">{{item.label}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </td>
      <td>
        <input type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="datas.singleBedPrice"/>
      </td>
    </tr>
  </table>
</template>
<script>
  export default {
    name: 'PriceForm',
    props: {
      datas: Object
    },
    data() {
      return {
        statusOption: [{label: this.$t('Available'), value: 'Y'}, {label: this.$t('Unavailable'), value: 'N'}]
      };
    },
    methods: {
      // 修改状态
      changeStatus(command, instance) {
        const datas = {...this.datas};
        const {key, value} = command;
        datas[key] = value;
        // 小童占房与小童不占床同步
        if (key === 'isChild') {
          datas.isNotChild = value;
        }
        this.$emit('update:datas', datas);
      },
      changePriceType(command, instance) {
        const datas = {...this.datas};
        datas.priceType = command.index;
        this.$emit('update:datas', datas);
      },
      getPriceData() {
        let priceData = {...this.datas};
        if (!priceData.childPrice || priceData.childPrice < 0 || !priceData.notChildPrice || priceData.notChildPrice < 0) {
          priceData.isChild = 'N';
          priceData.isNotChild = 'N';
        }
        if (!priceData.babyPrice || priceData.babyPrice < 0) {
          priceData.isBaby = 'N';
        }
        if (priceData.adultPrice < 0) {
          this.$message.warning(this.$t('Please fill out', [this.$t('Sell')]));
          return false;
        }
        return priceData;
      }
    }
  };
</script>
<style scoped lang="less">
  .table{
    width:100%;margin-top:24px;font-size:14px;
    td{
      height:32px;padding:0 20px;vertical-align:middle;border:1px solid #eee;
      &.txt-l{text-align:left;}
      &.label{color:#252525;background-color:#fbfbfb;}
    }
    input{width:100%;height:32px;text-align:center;border:none;}
    .link-status{color:#321908;}
    .el-icon-caret-bottom{color:#999;}
  }
</style>
