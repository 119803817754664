<template>
  <div class="month-wrap">
    <div class="year">
      <a href="javascript:;" class="btn-prev" @click="selectYear(-1)">
        <i class="el-icon-arrow-up"></i>
      </a>
      <div class="year-picker">
        <div class="inner" :style="{transform:`translateY(${translateY}px)`}">
          <div class="item"
               v-for="item of yearList"
               :class="{active:item===year}"
               :key="item"
               @click="setYear(item)">
            {{item}}年
          </div>
        </div>
      </div>
      <a href="javascript:;" class="btn-next" @click="selectYear(1)">
        <i class="el-icon-arrow-down"></i>
      </a>
    </div>
    <div class="month-list">
      <a class="item" href="javascript:;"
         v-for="item of monthList"
         :class="{active:item===month}"
         :key="item"
         @click="setMonth(item)">
        {{item}}月
      </a>
    </div>
  </div>
</template>
<script>
  import {formatNumber} from '../utils';

  export default {
    name: 'Month',
    props: {noDefaultValue: Boolean},
    data() {
      return {
        yearList: [],
        year: null,
        monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        month: null
      };
    },
    computed: {
      translateY: function () {
        return 48 - 48 * this.yearList.indexOf(this.year);
      }
    },
    mounted() {
      const yearList = [];
      const year = new Date().getFullYear();
      const maxYear = year + 10;
      const month = new Date().getMonth();
      for (let i = year; i <= maxYear; i++) {
        yearList.push(i);
      }
      this.yearList = yearList;
      this.year = year;
      if (!this.noDefaultValue) {
        this.month = month + 1;
        this.setValue();
      }
    },
    methods: {
      selectYear(value) {
        const year = this.year + value;
        if (this.yearList.includes(year)) {
          this.year = year;
          this.setValue();
        }
      },
      setMonth(month) {
        if (this.month !== month) {
          this.month = month;
          this.setValue();
        } else {
          if (this.noDefaultValue) {
            this.month = null;
            this.setValue();
          }
        }
      },
      setYear(year) {
        this.year = year;
        this.setValue();
      },
      setValue() {
        const value = this.month === null ? '' : `${this.year}/${formatNumber(this.month)}`;
        this.$emit('input', value);
        this.$emit('change', value);
      },
      clearMonth() {
        this.month = null;
      }
    }
  };
</script>
<style scoped lang="less">
  .month-wrap{
    position:relative;height:300px;overflow:hidden;background-color:#f4f4f4;border-radius:4px;user-select:none;
    &:after{display:inline-block;height:100%;vertical-align:middle;content:'';}
    .year{
      display:inline-block;min-width:80px;margin-left:30px;vertical-align:middle;white-space:nowrap;
      .item{
        height:48px;line-height:48px;text-align:center;opacity:0.3;color:#321908;cursor:pointer;transition:all 0.3s;
        &.active{opacity:1;font-size:24px;pointer-events:none;}
      }
      .btn-prev,
      .btn-next{
        display:block;line-height:40px;text-align:center;color:#bfbfbf;font-size:18px;
        &:hover{color:#321908;}
      }
    }
    .year-picker{
      position:relative;height:144px;overflow:hidden;
      .inner{transition:all 0.3s;}
    }
    .month-list{
      display:inline-block;width:384px;margin-left:55px;vertical-align:middle;
      > .item{
        float:left;width:96px;line-height:60px;text-align:center;opacity:0.3;color:#321908;font-size:24px;
        &:hover{opacity:0.6;}
        &.active{opacity:1;}
      }
    }
  }
</style>
