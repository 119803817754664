<template>
  <modal :title="$t('FlightInformation')" width="980px" :is-show="isShow" @close-modal="hide">
    <div class="edit-flight">
      <div class="title">{{title}}</div>
      <div class="radio-wrap">
        <radio v-model="isCustomize" label="N">{{$t('Detailed flight information')}}</radio>
      </div>
      <table class="table-wrap" @click="isCustomize='N'">
        <thead>
        <tr>
          <th>{{$t('Flight')}}</th>
          <th width="108">{{$t('Departure')}}</th>
          <th width="108">{{$t('Destination')}}</th>
          <th width="108">{{$t('Airline')}}</th>
          <th width="108">{{$t('Flight Number')}}</th>
          <th width="96">{{$t('Travel itinerary')}}</th>
          <th width="138">{{$t('Departure time')}}</th>
          <th width="138">{{$t('Arrival time')}}</th>
          <th width="50">{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) of flightDetails" :key="index">
          <td>{{$t('No.Schedule',[index+1])}}</td>
          <td>
            <el-select class="select"
                       popper-class="select-dropdown"
                       size="small"
                       :placeholder="$t('PleaseEnter')"
                       filterable
                       remote
                       :remote-method="(query)=>filterDepCity=query"
                       v-model="item.departure">
              <template v-for="citem of cityList">
                <el-option :key="citem.id"
                           :label="citem.name"
                           :value="citem.code"
                           v-if="citem.code===item.departure||(filterDepCity&&citem.name.includes(filterDepCity))">
                </el-option>
              </template>
            </el-select>
          </td>
          <td>
            <el-select class="select"
                       popper-class="select-dropdown"
                       size="small"
                       :placeholder="$t('PleaseEnter')"
                       filterable
                       remote
                       :remote-method="(query)=>filterDesCity=query"
                       v-model="item.destination">
              <template v-for="citem of cityList">
                <el-option :key="citem.id"
                           :label="citem.name"
                           :value="citem.code"
                           v-if="citem.code===item.destination||(filterDesCity&&citem.name.includes(filterDesCity))">
                </el-option>
              </template>
            </el-select>
          </td>
          <td>
            <el-select class="select"
                       popper-class="select-dropdown"
                       filterable
                       size="small"
                       :placeholder="$t('PleaseChoose')"
                       v-model="item.airline">
              <el-option v-for="item of airlineOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </td>
          <td>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.airlineNumber"/>
          </td>
          <td>
            <el-select class="select w1" size="small" v-model="item.fewDay">
              <template v-for="i in maxDay">
                <el-option :label="i" :value="i" :key="i" :disabled="disableFewDay(index,i)"></el-option>
              </template>
            </el-select>
          </td>
          <td>
            <time-picker class="select w1" size="small"
                         :editable="false"
                         :clearable="false"
                         prefix-icon="el-icon-arrow-down"
                         format="HH:mm"
                         value-format="HH:mm"
                         :placeholder="$t('PleaseChoose')"
                         @change="timeChange"
                         v-model="item.outsetTime"/>
          </td>
          <td>
            <time-picker class="select w1" size="small"
                         :editable="false"
                         :clearable="false"
                         prefix-icon="el-icon-arrow-down"
                         format="HH:mm"
                         value-format="HH:mm"
                         :placeholder="$t('PleaseChoose')"
                         @change="timeChange"
                         v-model="item.arrivalsTime"/>
            <checkbox class="time-checkbox" :value="!!item.addDay" @click.native="changeAddDay(item)">
              <span class="checkbox-label">+1</span>
            </checkbox>
          </td>
          <td>
            <a href="javascript:;" @click="deleteFlightItem(index)">
              <img class="vam" src="../../../../assets/images/icon/ashcan.png"/>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <a class="btn-add" @click="addFlightItem()">+ {{$t('Add a line')}}</a>
      </div>
      <div class="radio-wrap">
        <radio v-model="isCustomize" label="Y">{{$t('Custom content')}}</radio>
      </div>
      <div class="mt" @click="isCustomize='Y'">
<!--        <froala :config="froalaConfig" v-model="customizeContent"/>-->
<!--        <div id="editor"></div>-->
        <editor-elem  v-model="customizeContent"/>
      </div>

      <div class="actions">
        <button class="button button-primary" :disabled="isSaveing" @click="onSubmit">{{$t('Save')}}</button>
      </div>
    </div>
  </modal>
</template>
<script>
  import {TimePicker} from 'element-ui';
  import Modal from '../../../../components/Modal';
  import Radio from '../../../../components/Radio';
  import Checkbox from '../../../../components/Checkbox';
  import editor from '../../../../mixins/editor';
  import {getDictList, getAllCityList} from '../../../../api/admin-common';
  import {saveProductFlight, getProductFlightDetails} from '../../../../api/admin-product';
  import editorElem from '../../../../components/EditorBar';
  import {required} from 'vuelidate/lib/validators';
  import {scrollToY} from '@/utils';
  export default {
    name: 'EditFlightModal',
    props: ['productId', 'lineId', 'maxDay'],
    data() {
      return {
        isShow: false,
        language: '',
        cityList: [],
        airlineOptions: [],
        isCustomize: 'N',
        customizeContent: '',
        departureDate: [],
        flightDetails: [],
        isSaveing: false,
        editType: '',
        // 用于过滤出发地城市字典
        filterDepCity: '',
        // 用于过滤目的地城市字典
        filterDesCity: '',
        editor: ''
      };
    },
    computed: {
      title() {
        if (this.editType) {
          return {add: this.$t('BatchIncrease'), edit: this.$t('BatchEdit')}[this.editType];
        } else {
          return `${this.$t('Date of departure')}：${this.departureDate[0]}`;
        }
      }
    },
    // 詳情航班資料驗證規則
    validations: {
      flightDetails: {
        $each: {
          departure: {required},
          destination: {required},
          airline: {required},
          airlineNumber: {required},
          fewDay: {required},
          outsetTime: {required},
          arrivalsTime: {required}
        }
      }
    },
    components: {Checkbox, TimePicker, Radio, Modal, editorElem},
    mixins: [editor],
    created() {
      this.getDicts();
    },
    methods: {
      show(departureDate, language, editType = '') {
        this.departureDate = departureDate;
        this.language = language;
        this.editType = editType;
        this.getProductFlightDetails();
      },
      hide() {
        this.isShow = false;
      },
      // 获取数据字典
      getDicts() {
        // 获取所有城市
        getAllCityList().then(res => {
          this.cityList = res.value;
        });
        // 获取航班
        getDictList('airline').then(res => {
          this.airlineOptions = res.value || [];
        });
      },
      // 获取产品路线航班资料详情
      getProductFlightDetails() {
        const data = {lineId: this.lineId, languageType: this.language, departureDates: this.departureDate};
        const loading = this.$loading({text: 'Loading'});
        getProductFlightDetails(data).then(res => {
          const value = res.value;
          loading.close();
          // 单个的
          if (this.departureDate.length === 1) {
            const item = value[0] || {};
            this.flightDetails = item.flightDetails || [];
            this.isCustomize = item.isCustomize || 'N';
            this.customizeContent = item.customizeContent || '';
            if (!this.flightDetails.length) {
              this.addFlightItem(item.isCustomize);
            }
            this.isShow = true;
          } else { // 批量的
            let isSame = true; // 用于判断多个日期中的航班信息是否相同，不相同编辑前提示用户
            if (value.length) {
              if (this.departureDate.length !== value.length) {
                isSame = false;
              } else {
                const list = value.map(item => {
                  const newItem = {
                    flightDetails: (item.flightDetails || []).map(fItem => {
                      return {
                        departure: fItem.departure,
                        destination: fItem.destination,
                        airline: fItem.airline,
                        airlineNumber: fItem.airlineNumber,
                        fewDay: fItem.fewDay,
                        outsetTime: fItem.outsetTime,
                        arrivalsTime: fItem.arrivalsTime,
                        addDay: fItem.addDay
                      };
                    }),
                    isCustomize: item.isCustomize || 'N',
                    customizeContent: item.customizeContent || ''
                  };
                  return JSON.stringify(newItem);
                });
                for (let i in list) {
                  if (list[0] !== list[i]) {
                    isSame = false;
                    break;
                  }
                }
              }
            }
            // 如果批量的航班信息不相同
            if (!isSame) {
              this.$msgbox({
                title: this.$t('Prompt'),
                message: this.$t('confirm.flight'),
                center: true,
                showClose: false,
                showCancelButton: true,
                lockScroll: false
              }).then(action => {
                this.initBatchValue(value);
              }).catch(() => {
              });
            } else {
              this.initBatchValue(value);
            }
          }
        }).catch(() => {
          loading.close();
        });
      },
      // 批量增加、修改时赋值
      initBatchValue(value) {
        if (value.length) {
          this.flightDetails = value[0].flightDetails || [];
          this.isCustomize = value[0].isCustomize || 'N';
          this.customizeContent = value[0].customizeContent || '';
        } else {
          this.flightDetails = [];
          this.isCustomize = 'N';
          this.customizeContent = '';
          this.addFlightItem();
        }
        this.isShow = true;
      },
      // 增加航班
      addFlightItem(isCustomize = 'N') {
        this.flightDetails.push({
          departure: '',
          destination: '',
          airline: '',
          airlineNumber: '',
          fewDay: '',
          outsetTime: '',
          arrivalsTime: '',
          addDay: 0
        });
        this.isCustomize = isCustomize;
      },
      // 删除航班
      deleteFlightItem(index) {
        this.flightDetails.splice(index, 1);
      },
      timeChange() {
        for (let i in this.flightDetails) {
          const item = this.flightDetails[i];
          if (item.outsetTime && item.arrivalsTime) {
            const outsetTime = item.outsetTime.split(':');
            const arrivalsTime = item.arrivalsTime.split(':');
            if (outsetTime[0] > arrivalsTime[0]) {
              this.flightDetails[i].addDay = 1;
            } else if (outsetTime[0] === arrivalsTime[0] && outsetTime[1] > arrivalsTime[1]) {
              this.flightDetails[i].addDay = 1;
            }
          }
        }
      },
      changeAddDay(item) {
        if (item.outsetTime && item.arrivalsTime) {
          const outsetTime = item.outsetTime.split(':');
          const arrivalsTime = item.arrivalsTime.split(':');
          if (outsetTime[0] > arrivalsTime[0]) {
            item.addDay = 1;
          } else if (outsetTime[0] === arrivalsTime[0] && outsetTime[1] > arrivalsTime[1]) {
            item.addDay = 1;
          } else {
            item.addDay = item.addDay === 0 ? 1 : 0;
          }
        } else {
          item.addDay = item.addDay === 0 ? 1 : 0;
        }
      },
      disableFewDay(index, itemIdex) {
        const prevItem = this.flightDetails[index - 1];
        const nextItem = this.flightDetails[index + 1];
        if (prevItem && nextItem && prevItem.fewDay && nextItem.fewDay) {
          return itemIdex < prevItem.fewDay || itemIdex > nextItem.fewDay;
        } else if (prevItem && prevItem.fewDay) {
          return itemIdex < prevItem.fewDay;
        } else if (nextItem && nextItem.fewDay) {
          return itemIdex > nextItem.fewDay;
        } else {
          return false;
        }
      },
      // 保存
      onSubmit() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            if (document.querySelector('.is-error')) {
              scrollToY(undefined, document.querySelector('.is-error').offsetTop - 80);
            }
          });
          return false;
        }
        const data = {
          productId: this.productId,
          languageType: this.language,
          lineId: this.lineId,
          departureDate: this.departureDate.join(';'),
          isCustomize: this.isCustomize,
          customizeContent: this.customizeContent,
          flightDetails: this.flightDetails.map((item, index) => {
            item.stage = index + 1;
            return item;
          })
        };

        this.isSaveing = true;
        saveProductFlight(data).then(res => {
          this.$message.success(this.$t('SaveSuccessfully'));
          this.$emit('reload-list');
          this.hide();
          this.isSaveing = false;
        }).catch(() => {
          this.isSaveing = false;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .edit-flight{
    padding:30px;text-align:left;
    .title{
      position:relative;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .radio-wrap{margin-top:30px;}
    .mt{margin-top:16px;}
    .btn-add{
      display:block;margin-top:16px;line-height:32px;text-align:center;color:#818181;border:1px dashed #d9d9d9;border-radius:4px;
      &:hover{color:#ff6f61;border-color:#ff6f61;}
    }
    .ipt{width:92px;height:32px;border:none;background-color:transparent;}
    .select{
      width:92px;vertical-align:top;
      &.w1{width:80px;}
      /deep/ .el-input__inner{padding-left:0;border:none;cursor:pointer;background-color:transparent;}
      /deep/ .el-input__prefix{left:auto;right:5px;}
    }
  }
  .table-wrap{
    width:100%;margin-top:16px;
    th,
    td{padding:4px 8px;line-height:32px;}
    th,
    tbody tr:nth-child(even){background-color:#fbfbfb;}
  }
  .time-checkbox{display:inline-block;line-height:1;vertical-align:middle;}
</style>
