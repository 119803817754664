import { render, staticRenderFns } from "./SelectColumnModal.vue?vue&type=template&id=323c7db3&scoped=true"
import script from "./SelectColumnModal.vue?vue&type=script&lang=js"
export * from "./SelectColumnModal.vue?vue&type=script&lang=js"
import style0 from "./SelectColumnModal.vue?vue&type=style&index=0&id=323c7db3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323c7db3",
  null
  
)

export default component.exports