<template>
  <el-dialog :title="$t('BatchEdit')" width="700px" :close-on-click-modal="false" :visible.sync="visible">
    <div class="img-list">
      <multimedia-item v-for="item of multimediaList"
                       :item="item"
                       :big-picture-id="bigPictureId"
                       @update-bgid="updateBigPictureId"
                       :key="item.mediaId"/>
    </div>
    <div class="actions">
      <button class="button m-r" type="button" @click="visible=false">{{$t('Cancel')}}</button>
      <el-button class="button button-primary"
                 :loading="isSubmiting"
                 :disabled="disabledSubmit"
                 @click="onSubmit">{{$t('Save')}}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import MultimediaItem from './MultimediaItem';
import {saveProductMediaList} from '../../../../api/admin-product';

export default {
    name: 'MultimediaEditDialog',
    props: {
      productId: String,
      language: String,
      show: Boolean,
      list: Array,
      item: Object,
      bigPictureId: String
    },
    data() {
      return {
        visible: false,
        isSubmiting: false
      };
    },
    computed: {
      multimediaList: function () {
        if (this.item) {
          // return [{...this.item}];
          return [this.item];
        } else {
          return this.list;
        }
      },
      disabledSubmit: function () {
        let isDisabled = false;
        for (let item of this.multimediaList) {
          if (!item.title || !item.introduction || !item.photoUrl) {
            isDisabled = true;
            break;
          }
        }
        return isDisabled;
      }
    },
    components: {MultimediaItem},
    methods: {
      onSubmit() {
        const data = this.multimediaList.map(item => {
          const itemData = {...item};
          itemData.isDefault = itemData.mediaId === this.bigPictureId ? 'Y' : 'N';
          return itemData;
        });
        this.isSubmiting = true;
        saveProductMediaList(data, 'edit').then(res => {
          this.isSubmiting = false;
          this.$emit('update:show', false);
          this.$emit('reload-list');
          this.$message.success(this.$t('SaveSuccessfully'));
        }).catch(() => {
          this.isSubmiting = false;
        });
      },
      updateBigPictureId(value) {
        this.$emit('update:bigPictureId', value);
      }
    },
    watch: {
      show: function (value) {
        this.isSubmiting = false;
        this.visible = value;
        if (!value) {
          this.$emit('update:item', null);
        }
      },
      visible: function (value) {
        this.$emit('update:show', value);
      }
    }
  };
</script>
<style scoped lang="less">
  .img-list{max-height:50vh;margin:0 -20px;padding:0 20px;overflow-y:auto;}
</style>
