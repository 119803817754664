<template>
  <div class="edit-wrap m-t">
    <div class="cl">
      <div class="fl form-item cl">
        <div class="label">{{$t('Start date')}}：</div>
        <el-date-picker class="fl date-picker"
                        popper-class="selected-date"
                        v-model="dates"
                        size="small"
                        type="daterange"
                        :range-separator="$t('To')"
                        :start-placeholder="$t('StartDate')"
                        :end-placeholder="$t('EndDate')"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="fr form-item cl">
        <div class="label">{{$t('Currency')}}：</div>
        <input class="fl ipt w1 txt-l" type="text" disabled="disabled" :value="$t('Currencys')[paymentCurrency]"/>
      </div>
    </div>
    <div class="cl">
      <checkbox class="fl" :can-click="false" v-model="everyDay" @click.native="selectAllDays">
        <div class="checkbox-label">{{$t('EveryDay')}}</div>
      </checkbox>
      <checkbox class="fl" v-for="item of weeks" v-model="item.isSelected" :key="item.value">
        <div class="checkbox-label">{{item.name}}</div>
      </checkbox>
    </div>
    <price-form :datas.sync="priceForm" ref="priceForm"/>
    <div class="form-item cl m-t">
      <div class="label">{{$t('MaximumNumberOfPeople')}}：</div>
      <div class="fl">
        <input class="ipt" type="number" autocomplete="off" :disabled="isLimitPeople" :placeholder="$t('PleaseEnter')"
               v-model="limitPeopleNumber"/>
      </div>
      <div class="unit">{{$t('People')}}</div>
      <checkbox class="fl" style="margin:8px 0 0 24px" v-model="isLimitPeople">
        <div class="checkbox-label">{{$t('NoUpperLimit')}}</div>
      </checkbox>
    </div>
    <div class="form-item cl m-t">
      <div class="label">{{$t('GroupOfPeople')}}：</div>
      <div class="fl">
        <input class="ipt" type="number" autocomplete="off" :placeholder="$t('PleaseEnter')"
               v-model="groupPeopleNumber"/>
      </div>
      <div class="unit">{{$t('People')}}</div>
    </div>
    <div class="form-item cl m-t">
      <div class="label" style="margin-right:12px">{{$t('After the time: before the group')}}</div>
      <div class="fl">
        <input class="ipt" type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="obtainedDays"/>
      </div>
      <div class="unit">{{$t('Day')}}</div>
    </div>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('SaveAndPublish')}}</button>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Checkbox from '../../../../components/Checkbox';
  import PriceForm from './PriceForm';
  import {addProductPriceByWeek} from '../../../../api/admin-product';

  export default {
    name: 'AddPriceByWeek',
    props: {
      productId: String,
      lineId: String,
      priceDates: Array
    },
    data() {
      return {
        dates: [],
        weeks: [
          {name: this.$t('EverySunday'), value: '0', isSelected: false},
          {name: this.$t('EveryMonday'), value: '1', isSelected: false},
          {name: this.$t('EveryTuesday'), value: '2', isSelected: false},
          {name: this.$t('EveryWednesday'), value: '3', isSelected: false},
          {name: this.$t('EveryThursday'), value: '4', isSelected: false},
          {name: this.$t('EveryFriday'), value: '5', isSelected: false},
          {name: this.$t('EverySaturday'), value: '6', isSelected: false}
        ],
        isLimitPeople: false,
        limitPeopleNumber: '',
        groupPeopleNumber: '',
        obtainedDays: '',
        priceForm: {
          isAdult: 'Y',
          adultPrice: '',
          isChild: 'Y',
          childPrice: '',
          isNotChild: 'Y',
          notChildPrice: '',
          isBaby: 'Y',
          babyPrice: '',
          isSingleBed: 'Y',
          singleBedPrice: '',
          priceType: 0
        }
      };
    },
    computed: {
      ...mapGetters(['paymentCurrency']),
      everyDay() {
        return this.weeks.length === this.weeks.filter(item => item.isSelected).length;
      },
      priceTimestamps() {
        return this.priceDates.map(item => {
          const ts = new Date(item.replace(/-/g, '/')).getTime();
          return ts;
        });
      },
      pickerOptions() {
        return {
          disabledDate: (time) => {
            return Date.now() > time.getTime() || this.priceTimestamps.includes(time.getTime());
          },
          cellClassName: (date) => {
            return this.priceTimestamps.includes(date.getTime()) ? 'is-select' : '';
          }
        };
      }
    },
    components: {PriceForm, Checkbox},
    methods: {
      selectAllDays() {
        const isSelected = !this.everyDay;
        this.weeks = this.weeks.map(item => {
          item.isSelected = isSelected;
          return item;
        });
      },
      // 保存并发布
      onSubmit() {
        const data = {
          productId: this.productId,
          lineId: this.lineId,
          startDate: this.dates[0],
          endDate: this.dates[1],
          weeks: this.weeks.filter(item => item.isSelected).map(item => item.value).join(';'),
          isLimitPeople: this.isLimitPeople ? 'N' : 'Y',
          limitPeopleNumber: this.limitPeopleNumber,
          groupPeopleNumber: this.groupPeopleNumber,
          obtainedDays: this.obtainedDays
        };
        const priceData = this.$refs.priceForm.getPriceData();

        if (!data.startDate || !data.weeks) {
          this.$message.warning(this.$t('Please fill out', [this.$t('Start date')]));
          return false;
        }
        if (!priceData) {
          return false;
        }

        addProductPriceByWeek({...data, ...priceData}).then(res => {
          this.$message.success('發佈成功');
          this.$emit('close');
          this.$emit('refresh-price');
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .edit-wrap{
    .m-t{margin-top:24px;}
    /deep/ .checkbox-wrap{min-width:105px;margin-top:16px;padding-right:10px;font-size:16px;}
  }
  .form-item{
    .label{float:left;line-height:32px;color:#321908;font-size:16px;}
    .ipt{
      width:160px;height:32px;padding:0 10px;text-align:center;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:112px;}
    }
    .unit{float:left;margin-left:12px;line-height:32px;}
  }
  .date-picker{
    width:320px;
    /deep/ .el-range-input{font-size:14px;}
    /deep/ .el-range-separator{padding:0;font-size:14px;}
  }
</style>
