<template>
  <modal :title="$t('Column selection')" :is-show="isShow" @close-modal="hide">
    <div class="form">
      <div class="form-item">
        <div class="label tit">{{$t('Column')}}</div>
        <div class="operating">
          <a class="btn-checkbox" :class="{active:isSelectedAll}" @click="selectedAll">
            <i class="icons i-checkbox"></i>
            <span class="vam">{{$t('All')}}</span>
          </a>
        </div>
      </div>
      <div class="form-item"
           v-for="(item,index) of list"
           :class="{'is-edit':item.isEdit}"
           :key="item.programLabel">
        <div class="label">
          <div class="edit-wrap" v-if="item.isCustomize==='Y'">
            <div class="ipt-wrap">
              <input class="ipt"
                     autocomplete="off"
                     :placeholder="$t('PleaseEnter')"
                     v-focus
                     v-model.trim="item.programName"
                     v-if="item.isEdit"/>
              <template v-else>{{item.programName}}</template>
            </div>
            <el-tooltip effect="dark"
                        :content="$t('Please save first')"
                        :value="showTips&&!item.programId"
                        placement="right"
                        manual
                        v-if="item.isEdit">
              <a class="primary" :disabled="!item.programName" @click="saveProductColumn(index)">{{$t('Save')}}</a>
            </el-tooltip>
            <a v-else @click="list[index].isEdit=1">{{$t('Edit')}}</a>
            <a @click="deleteProductColumn(index)">{{$t('Delete')}}</a>
          </div>
          <template v-else>{{item.programName}}</template>
        </div>
        <div class="operating">
          <a class="btn-checkbox" :class="{active:selectedList.includes(item.programId)}"
             :disabled="defaultColumn.includes(item.programLabel)"
             @click="onSelect(item.programId,item.programLabel)"
             v-if="item.programId">
            <i class="icons i-checkbox"></i>
            <span class="vam">{{$t('Select')}}</span>
          </a>
        </div>
      </div>
      <div class="form-item">
        <div class="txt-c">
          <a class="primary" href="javascript:;" @click="addItem">
            <i class="icons i-plus"></i><span class="vam">{{$t('Customize')}}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="button button-primary" :disabled="!selectedList.length" @click="saveProductLineColumn">
        {{$t('Save')}}
      </button>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../../../components/Modal';
  import {
    getProductColumnList,
    saveProductColumn,
    deleteProductColumn,
    saveProductLineColumn
  } from '../../../../api/admin-product';

  export default {
    name: 'SelectColumnModal',
    directives: {
      focus: {
        inserted: function (el) {
          el.focus();
        }
      }
    },
    props: {language: String, productId: String, lineId: String, selectedColumnList: Array, defaultColumn: Array},
    data() {
      return {
        isShow: false,
        list: [],
        selectedList: [],
        showTips: false
      };
    },
    computed: {
      isSelectedAll() {
        return this.list.length === this.selectedList.length;
      }
    },
    components: {Modal},
    created() {
      this.getProductColumnList();
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      // 选中或取消选中
      onSelect(programId, programLabel) {
        // 产品介绍是必选项，不能取消选中
        if (this.defaultColumn.includes(programLabel)) {
          return false;
        }
        if (this.selectedList.includes(programId)) {
          this.selectedList.splice(this.selectedList.indexOf(programId), 1);
        } else {
          this.selectedList.push(programId);
        }
      },
      // 全选
      selectedAll() {
        const isSelectAll = !this.isSelectedAll;
        if (isSelectAll) {
          this.selectedList = this.list.map(item => item.programId);
        } else {
          // 由于产品介绍是必选项，所以要获取产品介绍的programId
          const introduction = this.list.filter(item => this.defaultColumn.includes(item.programLabel)).map(item => item.programId);
          this.selectedList = introduction;
        }
      },
      // 添加一项自定义
      addItem() {
        if (this.list.find(item => !item.programId)) {
          if (!this.showTips) {
            this.showTips = true;
            setTimeout(() => {
              this.showTips = false;
            }, 6000);
          }
          return false;
        }
        this.list.push({
          languageType: this.language,
          programId: '',
          programLabel: `zdy${new Date().getTime()}`,
          programName: '',
          isCustomize: 'Y',
          isEdit: 1
        });
      },
      // 获取产品栏目列表
      getProductColumnList() {
        getProductColumnList(this.language).then(res => {
          this.list = res.value.map(item => {
            if (item.isCustomize === 'Y') {
              item.isEdit = 0;
            }
            return item;
          });
        });
      },
      // 保存自定义栏目
      saveProductColumn(index) {
        const item = this.list[index];
        const data = {
          programId: item.programId,
          programLabel: item.programLabel,
          languageType: item.languageType,
          programName: item.programName
        };
        saveProductColumn(data).then(res => {
          const value = res.value;
          this.list[index].programId = value.programId;
          this.list[index].isEdit = 0;
          this.$emit('reload-list');
          this.$message.success(this.$t('SaveSuccessfully'));
        });
      },
      // 删除自定义栏目
      deleteProductColumn(index) {
        const programId = this.list[index].programId;
        if (!programId) {
          this.list.splice(index, 1);
        } else {
          this.$msgbox({
            title: this.$t('Prompt'),
            message: this.$t('confirm.delete'),
            center: true,
            showClose: false,
            showCancelButton: true,
            lockScroll: false,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                deleteProductColumn(programId).then(res => {
                  instance.confirmButtonLoading = false;
                  done();
                  this.$message.success('刪除成功!');
                  this.list.splice(index, 1);
                }).catch(() => {
                  instance.confirmButtonLoading = false;
                });
              } else {
                done();
              }
            }
          }).then(() => {
          }).catch(() => {
          });
        }
      },
      // 栏目与产品路线关联
      saveProductLineColumn() {
        const data = this.selectedList.map(item => {
          return {productId: this.productId, lineId: this.lineId, programId: item};
        });
        saveProductLineColumn(data).then(res => {
          this.$emit('reload-list');
          this.isShow = false;
          this.$message.success(this.$t('SaveSuccessfully'));
        });
      }
    },
    watch: {
      selectedColumnList: function (value) {
        this.selectedList = value.map(item => item.programId);
      }
    }
  };
</script>
<style scoped lang="less">
  .form{
    margin:30px 30px 0;text-align:left;border:1px solid #eee;
    .form-item{
      line-height:32px;overflow:hidden;border-bottom:1px solid #eee;
      &:last-child{border-bottom:none;}
      &.is-edit{
        .label{background-color:#fff;}
      }
    }
    .tit{color:#000;font-weight:bold;}
    .label{float:left;width:430px;padding:0 20px;border-right:1px solid #eee;background-color:#fbfbfb;}
    .operating{margin-left:430px;padding:0 20px;background-color:#fff;}
    .edit-wrap{
      display:flex;align-items:center;
      > a{margin-left:8px;cursor:pointer;}
    }
    .ipt-wrap{flex:1;}
    .ipt{width:100%;height:31px;border:none;background-color:transparent;}
    .primary{color:#ff6f61;}
  }
  .actions{padding-bottom:30px;}
  .btn-checkbox{
    color:#321908;cursor:pointer;user-select:none;
    &.active{
      .i-checkbox{background-image:url(../../../../assets/images/checkbox_selected.png);}
    }
  }
  .i-checkbox{width:16px;height:16px;margin-right:4px;vertical-align:middle;background-image:url(../../../../assets/images/checkbox.png);}
  .i-plus{width:16px;height:16px;margin-right:4px;vertical-align:middle;background-image:url(../../../../assets/images/icon/plus-circle-red.png);}
</style>
