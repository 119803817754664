<template>
  <modal :title="$t('ModifyThePrice')" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="edit-wrap">
      <div class="cl">
        <div class="fl form-item cl">
          <div class="label">{{$t('Date')}}：{{priceDate}}</div>
        </div>
        <div class="fr form-item cl">
          <div class="label">{{$t('Currency')}}：</div>
          <input class="fl ipt txt-l" type="text" disabled="disabled" :value="$t('Currencys')[paymentCurrency]"/>
        </div>
      </div>
      <price-form :datas.sync="priceForm" ref="priceForm"/>
      <div class="form-item cl m-t">
        <div class="label">{{$t('MaximumNumberOfPeople')}}：</div>
        <div class="fl">
          <input class="ipt" type="number" autocomplete="off" :disabled="isLimitPeople" :placeholder="$t('PleaseEnter')"
                 v-model="limitPeopleNumber"/>
        </div>
        <div class="unit">{{$t('People')}}</div>
        <checkbox class="fl" style="margin:8px 0 0 24px" v-model="isLimitPeople">
          <div class="checkbox-label">{{$t('NoUpperLimit')}}</div>
        </checkbox>
      </div>
      <div class="form-item cl m-t">
        <div class="label">{{$t('GroupOfPeople')}}：</div>
        <div class="fl">
          <input class="ipt" type="number" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model="groupPeopleNumber"/>
        </div>
        <div class="unit">{{$t('People')}}</div>
      </div>
      <div class="form-item cl m-t">
        <div class="label" style="margin-right:12px">{{$t('After the time: before the group')}}</div>
        <div class="fl">
          <input class="ipt" type="number" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model="obtainedDays"/>
        </div>
        <div class="unit">{{$t('Day')}}</div>
      </div>
      <div class="actions">
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('SaveAndPublish')}}</button>
      </div>
    </div>
  </modal>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Modal from '../../../../components/Modal';
  import Checkbox from '../../../../components/Checkbox';
  import PriceForm from './PriceForm';
  import {getProductPriceByDate, updateProductPriceByDate} from '../../../../api/admin-product';

  export default {
    name: 'BatchEditModal',
    props: {
      productId: String,
      lineId: String
    },
    data() {
      return {
        isShow: false,
        priceId: '',
        priceDate: '',
        isLimitPeople: false,
        limitPeopleNumber: '',
        groupPeopleNumber: '',
        obtainedDays: '',
        priceForm: null
      };
    },
    computed: {...mapGetters(['paymentCurrency'])},
    components: {PriceForm, Modal, Checkbox},
    methods: {
      show(priceDate) {
        this.priceDate = priceDate;
        this.getProductPriceByDate();
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      // 获取产品路线价格按日期
      getProductPriceByDate() {
        getProductPriceByDate(this.lineId, this.priceDate).then(res => {
          const value = res.value || {};
          this.priceId = value.priceId;
          this.isLimitPeople = value.isLimitPeople === 'N';
          this.limitPeopleNumber = value.limitPeopleNumber;
          this.groupPeopleNumber = value.groupPeopleNumber;
          this.obtainedDays = value.obtainedDays;
          this.priceForm = {
            isAdult: value.isAdult,
            adultPrice: value.adultPrice,
            isChild: value.isChild || 'Y',
            childPrice: value.childPrice,
            isNotChild: value.isChild || 'Y',
            notChildPrice: value.notChildPrice,
            isBaby: value.isBaby,
            babyPrice: value.babyPrice,
            isSingleBed: value.isSingleBed,
            singleBedPrice: value.singleBedPrice,
            priceType: ~~value.priceType
          };
        });
      },
      // 保存并发布
      onSubmit() {
        const data = {
          productId: this.productId,
          lineId: this.lineId,
          priceId: this.priceId,
          isLimitPeople: this.isLimitPeople ? 'N' : 'Y',
          limitPeopleNumber: this.limitPeopleNumber,
          groupPeopleNumber: this.groupPeopleNumber,
          obtainedDays: this.obtainedDays
        };
        const priceData = this.$refs.priceForm.getPriceData();

        if (!priceData) {
          return false;
        }

        updateProductPriceByDate({...data, ...priceData}).then(res => {
          this.$message.success('發佈成功');
          this.isShow = false;
          this.$emit('refresh-price');
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .edit-wrap{
    padding:30px 30px 40px;
    .m-t{margin-top:24px;}
    /deep/ .checkbox-wrap{min-width:105px;margin-bottom:16px;padding-right:10px;font-size:16px;}
  }
  .form-item{
    .label{float:left;line-height:32px;color:#321908;font-size:16px;}
    .ipt{
      width:160px;height:32px;padding:0 10px;text-align:center;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .unit{float:left;margin-left:12px;line-height:32px;}
  }
  .date-picker{
    width:320px;
    /deep/ .el-range-input{font-size:14px;}
    /deep/ .el-range-separator{padding:0;font-size:14px;}
  }
</style>
