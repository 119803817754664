<template>
  <div class="tourist-route">
    <title-code :language="language" :product-id="productId" :product-title="productTitle" :product-code="productCode"/>
    <route-tabs-nav :language="language" :product-id="productId" :list.sync="line" v-model="lineId"/>
    <template v-if="line.length&&line[0].lineId">
      <column-tabs-nav :language="language"
                       :product-id="productId"
                       :line-id="lineId"
                       :selected-list.sync="tabsList"
                       v-model="currentSubTabsNav"/>
      <product-description class="ani-fade-in"
                           :product-id="productId"
                           :language="language"
                           :line-id="lineId"
                           :isDay="isDay"
                           v-if="currentSubTabsNav==='introduction'"/>
      <schedule class="ani-fade-in"
                :product-id="productId"
                :language="language"
                :line-id="lineId"
                v-if="currentSubTabsNav==='travel'"/>
      <cost-details class="ani-fade-in"
                    :product-id="productId"
                    :language="language"
                    :line-id="lineId"
                    v-if="currentSubTabsNav==='cost'"/>
      <self-funded class="ani-fade-in"
                   :product-id="productId"
                   :language="language"
                   :line-id="lineId"
                   program-label="optional"
                   :program-name="programNameObj.optional"
                   key="SelfFunded"
                   v-if="currentSubTabsNav==='optional'"/>
      <self-funded class="ani-fade-in"
                   :product-id="productId"
                   :language="language"
                   :line-id="lineId"
                   program-label="booking"
                   :program-name="programNameObj.booking"
                   key="BookingInstructions"
                   v-if="currentSubTabsNav==='booking'"/>
      <visa class="ani-fade-in"
            :product-id="productId"
            :language="language"
            :line-id="lineId"
            v-if="currentSubTabsNav==='visa'"/>
      <self-funded class="ani-fade-in"
                   :product-id="productId"
                   :language="language"
                   :line-id="lineId"
                   program-label="responsibility"
                   :program-name="programNameObj.responsibility"
                   key="LiabilityClause"
                   v-if="currentSubTabsNav==='responsibility'"/>
      <cancellation-policy class="ani-fade-in"
                           :product-id="productId"
                           :language="language"
                           :line-id="lineId"
                           program-label="policy"
                           :program-name="programNameObj.policy"
                           v-if="currentSubTabsNav==='policy'"/>
      <upload-schedule class="ani-fade-in"
                       :product-id="productId"
                       :language="language"
                       :line-id="lineId"
                       v-if="currentSubTabsNav==='file'"/>
      <template v-for="item of customizeList">
        <custom-item class="ani-fade-in"
                     :product-id="productId"
                     :language="language"
                     :line-id="lineId"
                     :item="item"
                     :key="item.programLabel"
                     v-if="currentSubTabsNav===item.programLabel"/>
      </template>
    </template>
  </div>
</template>
<script>
  import RouteTabsNav from './RouteTabsNav';
  import ColumnTabsNav from './ColumnTabsNav';
  import ProductDescription from './ProductDescription';
  import Schedule from './Schedule';
  import CostDetails from './CostDetails';
  import SelfFunded from './SelfFunded';
  import CancellationPolicy from './CancellationPolicy';
  import UploadSchedule from './UploadSchedule';
  import CustomItem from './CustomItem';
  import Visa from './Visa';
  import TitleCode from './TitleCode';

  export default {
    name: 'TouristRoute',
    props: {
      productId: String,
      language: String,
      productCode: String,
      productTitle: String,
      isDay: String
    },
    data() {
      return {
        line: [],
        lineId: '',
        tabsList: [],
        currentSubTabsNav: ''
      };
    },
    computed: {
      customizeList() {
        return this.tabsList.filter(item => item.isCustomize === 'Y');
      },
      programNameObj() {
        let obj = {};
        for (let item of this.tabsList) {
          obj[item.programLabel] = item.programName;
        }
        return obj;
      }
    },
    components: {
      TitleCode,
      Visa,
      ColumnTabsNav,
      RouteTabsNav,
      ProductDescription,
      Schedule,
      CostDetails,
      SelfFunded,
      CancellationPolicy,
      UploadSchedule,
      CustomItem
    },
    methods: {
      // 下一步
      nextStep() {
        console.log(this.$parent);
        this.$emit('next-step', 'PriceDate');
        console.log(this.$props['isDay']);
      }
    },
    watch: {
      lineId: function (value) {
        this.$emit('reload-line', [...this.line]);
      }
    }
  };
</script>
<style scoped lang="less">
  .ani-fade-in{animation-name:fadeIn;animation-duration:0.6s;animation-fill-mode:backwards;}
  @keyframes fadeIn{
    0%{opacity:0;transform:translateY(20px);}
    100%{opacity:1;transform:translateY(0);}
  }
</style>
