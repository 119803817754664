import { render, staticRenderFns } from "./BatchCloseModal.vue?vue&type=template&id=61730c17&scoped=true"
import script from "./BatchCloseModal.vue?vue&type=script&lang=js"
export * from "./BatchCloseModal.vue?vue&type=script&lang=js"
import style0 from "./BatchCloseModal.vue?vue&type=style&index=0&id=61730c17&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61730c17",
  null
  
)

export default component.exports