<template>
  <div class="upload-wrap">
    <el-progress class="upload-progress" :width="80" type="circle" color="#ff6f61" :percentage="progress"
                 v-if="showProgress"/>
    <el-upload class="upload-cover"
               accept="image/*"
               action="/v1"
               :show-file-list="false"
               :http-request="uploadPicture"
               :before-upload="beforeAvatarUpload"
               v-else>
      <img class="img" :src="value" v-if="value"/>
      <div class="btn-upload" v-else>
        <img src="../assets/images/icon/plus.png"/>
        <span>{{$t('UploadPhotos')}}</span>
      </div>
    </el-upload>
  </div>
</template>
<script>
  import {upload} from '../api/common';
  import * as adminCommon from '../api/admin-common';

  export default {
    name: 'UploadCover',
    props: {
      value: String,
      httpType: {
        type: String,
        default: 'v1'
      }
    },
    data() {
      return {
        showProgress: false,
        progress: 0
      };
    },
    methods: {
      // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
          this.$message.error('圖片大小不能超過 2MB!');
        }
        return isLt2M;
      },
      // 覆盖默认的上传行为，可以自定义上传的实现
      uploadPicture(param) {
        let formData = new FormData();
        formData.append('file', param.file);
        this.showProgress = true;
        if (this.httpType === 'v1') {
          upload(formData, this.onUploadProgress).then(res => {
            this.$emit('input', res.value.url);
            this.showProgress = false;
            this.progress = 0;
          }).catch(() => {
            this.showProgress = false;
            this.progress = 0;
          });
        } else {
          adminCommon.upload(formData, this.onUploadProgress).then(res => {
            this.$emit('input', res.value.url);
            this.showProgress = false;
            this.progress = 0;
          }).catch(() => {
            this.showProgress = false;
            this.progress = 0;
          });
        }
      },
      // 上传进度
      onUploadProgress(e) {
        this.progress = Number(((e.loaded / e.total * 100) || 0).toFixed(2));
      }
    }
  };
</script>
<style scoped lang="less">
  .upload-wrap{position:relative;width:120px;height:120px;background-color:#f4f4f4;}
  .upload-progress{position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);}
  .upload-cover{
    width:100%;height:100%;
    /deep/ .el-upload{width:100%;height:100%;}
    .btn-upload{
      height:100%;overflow:hidden;color:#999;font-size:16px;
      img{display:block;margin:30px auto 8px;}
    }
  }
</style>
