<template>
  <div class="price-date" v-if="isShow">
    <title-code :language="language" :product-id="productId" :product-title="productTitle" :product-code="productCode"/>
    <div class="tabs-nav cl">
      <div class="item"
           v-for="(item,index) of line"
           :class="{active:index===lineIndex}"
           :key="item.id"
           @click="lineIndex=index">
        <div class="letter">{{item.lineName}}</div>
        <div class="line-title">{{item.lineTitle}}</div>
      </div>
    </div>
    <div class="actions txt-l">
      <button class="button" type="button" @click="showAddPriceModal">{{$t('AddPrice')}}</button>
      <button class="button" type="button" @click="showBatchEditModal">{{$t('BatchEdit')}}</button>
      <button class="button" type="button" @click="showBatchCloseModal">{{$t('BatchClose')}}</button>
    </div>
    <div class="price-table">
      <month v-model="month"/>
      <table>
        <tr rowspan="2">
          <td class="th" height="33"></td>
          <td class="th" width="75" height="33">{{$t('Sunday')}}</td>
          <td class="th" width="75" height="33">{{$t('Monday')}}</td>
          <td class="th" width="75" height="33">{{$t('Tuesday')}}</td>
          <td class="th" width="75" height="33">{{$t('Wednesday')}}</td>
          <td class="th" width="75" height="33">{{$t('Thursday')}}</td>
          <td class="th" width="75" height="33">{{$t('Friday')}}</td>
          <td class="th" width="75" height="33">{{$t('Saturday')}}</td>
        </tr>
        <tr v-for="(group,index) of monthPrice" :key="index">
          <td>
            <div class="td-item day"></div>
            <div class="td-item label">
              <span>{{$t('Passenger uniform price')}}</span>
            </div>
            <div class="td-item label">
              <span>{{$t('AdultPrice')}}</span>
            </div>
            <div class="td-item label">
              <span>{{$t('ChildPrice')}}</span>
            </div>
            <div class="td-item label">
              <span>{{$t('NotChildPrice')}}</span>
            </div>
            <div class="td-item label">
              <span>{{$t('BabyPrice')}}</span>
            </div>
            <div class="td-item label">
              <span>{{$t('SingleBedDifference')}}</span>
            </div>
            <div class="td-item label">
              <span>{{$t('Stock')}}({{$t('Sold')}}/{{$t('Sum')}})</span>
            </div>
            <div class="td-item label">
              <span>{{$t('Deadline for join')}}</span>
            </div>
          </td>
          <td v-for="item of group"
              :class="{'is-disabled':item.isStatus==='close','is-edit':item.lineId&&item.curMonth===0}"
              :key="item.dateStr">
            <div class="td-item day">
              <span>{{item.day}}</span>
              <i class="icon i-edit" :title="$t('Modify')" @click="showDayEdit(item.dateStr)"></i>
              <i class="icon i-ashcan" :title="$t('Closedown')"
                 @click="closeProductPrice(item.dateStr)"
                 v-if="item.isStatus!=='close'"></i>
              <i class="icon i-unlock" :title="$t('Open')" @click="openProductPrice(item.dateStr)" v-else></i>
            </div>
            <div class="td-item value">
              <span>{{item.priceType==='1'?item.adultPrice:''}}</span>
            </div>
            <div class="td-item value">
              <span>{{item.priceType!=='1'?item.adultPrice:''}}</span>
            </div>
            <div class="td-item value">
              <span>{{item.childPrice}}</span>
            </div>
            <div class="td-item value">
              <span>{{item.notChildPrice}}</span>
            </div>
            <div class="td-item value">
              <span>{{item.babyPrice}}</span>
            </div>
            <div class="td-item value">
              <span>{{item.singleBedPrice}}</span>
            </div>
            <div class="td-item value">
              <span>{{item.sellPeopleNumber}}/{{item.isLimitPeople==='N'?$t('NotSet'):item.limitPeopleNumber}}</span>
            </div>
            <div class="td-item value">{{item.obtainedDate|formatDate('yyyy-MM-dd')}}</div>
          </td>
        </tr>
      </table>
    </div>
    <!--<div class="actions">
      <button class="button button-primary" type="button">{{$t('PublishLatestPrice')}}</button>
    </div>-->
    <add-price-modal :product-id="productId" :line-id="lineId" :price-dates="priceDates" :price-date-status = "priceDateStatus"
                     @refresh-price="getProductPriceList"
                     ref="addPrice"/>
    <batch-edit-modal :product-id="productId" :line-id="lineId" :price-dates="priceDates"
                      @refresh-price="getProductPriceList"
                      ref="batchEdit"/>
    <batch-close-modal :product-id="productId" :line-id="lineId" :price-dates="priceDates" :price-date-status = "priceDateStatus"
                       @refresh-price="getProductPriceList"
                       ref="batchClose"/>
    <day-edit-modal :product-id="productId" :line-id="lineId" @refresh-price="getProductPriceList" ref="dayEdit"/>
  </div>
</template>
<script>
  import CalendarHelper from 'calendar-helper/index';
  import Month from '../../../../components/Month';
  import TitleCode from './TitleCode';
  import AddPriceModal from './AddPriceModal';
  import BatchEditModal from './BatchEditModal';
  import BatchCloseModal from './BatchCloseModal';
  import DayEditModal from './DayEditModal';
  import {
    getProductLine,
    getProductPriceList,
    closeProductPrice,
    openProductPrice
  } from '../../../../api/admin-product';

  export default {
    name: 'PriceDate',
    props: {
      productId: String,
      language: String,
      productCode: String,
      productTitle: String
    },
    data() {
      return {
        isShow: false,
        line: [],
        lineIndex: 0,
        month: '',
        priceList: []
      };
    },
    computed: {
      lineId: function () {
        return this.line.length ? this.line[this.lineIndex].lineId : '';
      },
      monthData: function () {
        if (this.month) {
          const date = new Date(`${this.month}/01`);
          return CalendarHelper.getMonthData(date, new Date());
        } else {
          return [];
        }
      },
      monthPrice: function () {
        return this.monthData.map(weekItem => {
          const weeks = JSON.parse(JSON.stringify(weekItem));
          return weeks.map(item => {
            if (item.curMonth === 0) {
              const dateStr = item.dateStr;
              const price = this.priceList.find(priceItem => priceItem.priceDate === dateStr);
              Object.assign(item, price || {});
              return item;
            } else {
              return {dateStr: item.dateStr};
            }
          });
        });
      },
      priceDates() {
        return this.priceList.map(item => item.priceDate);
      },
      priceDateStatus() {
        var object = this.priceList.length;
        var a = {};
        for (var obj = 0; obj < object; obj++) {
          a[this.priceList[obj].priceDate] = this.priceList[obj].isStatus;
        }
        console.log(a);
        return a;
      }
    },
    components: {TitleCode, Month, AddPriceModal, BatchEditModal, BatchCloseModal, DayEditModal},
    created() {
      this.getProductLine();
    },
    methods: {
      // 显示新增价格弹窗
      showAddPriceModal() {
        this.$refs.addPrice.show();
      },
      // 显示批量修改弹窗
      showBatchEditModal() {
        this.$refs.batchEdit.show();
      },
      // 显示批量关闭弹窗
      showBatchCloseModal() {
        this.$refs.batchClose.show();
      },
      // 显示单天修改弹窗
      showDayEdit(dateStr) {
        this.$refs.dayEdit.show(dateStr);
      },
      // 获取产品路线
      getProductLine() {
        getProductLine(this.language, this.productId).then(res => {
          this.line = res.value || [];
          this.isShow = true;
        });
      },
      // 获取产品路线价格集合
      getProductPriceList() {
        getProductPriceList(this.lineId).then(res => {
          this.priceList = res.value || [];
        });
      },
      // 关闭产品路线价格
      closeProductPrice(dateStr) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.closePrice', [dateStr]),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              closeProductPrice(this.lineId, [dateStr]).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.getProductPriceList();
                this.$message.success(this.$t('SaveSuccessfully'));
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 打开产品路线价格
      openProductPrice(dateStr) {
        openProductPrice(this.lineId, [dateStr]).then(res => {
          this.getProductPriceList();
          this.$message.success(this.$t('SaveSuccessfully'));
        });
      }
    },
    watch: {
      lineId: function (value) {
        this.getProductPriceList();
      }
    }
  };
</script>
<style scoped lang="less">
  .price-date{
    .actions.txt-l{
      margin-top:24px;text-align:left;
      > button{min-width:130px;margin-right:8px;}
    }
    /deep/ .month-wrap{
      border:1px solid #eee;background-color:#fbfbfb;border-radius:0;
      .year{margin-left:70px;}
      .month-list{margin-left:65px;}
    }
  }
  .tabs-nav{
    margin-top:22px;padding-bottom:20px;border-bottom:1px dashed #ccc;
    .item{
      float:left;width:132px;height:44px;margin-top:8px;margin-right:8px;padding:0 20px;padding:4px 10px;line-height:17px;text-align:center;color:#999;font-size:18px;border:1px dashed #ccc;border-radius:4px;cursor:pointer;
      &.active{color:#ff6f61;border-color:#ff6f61;pointer-events:none;}
      &:hover{border-color:#ff6f61;}
    }
    .letter{font-size:18px;}
    .line-title{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px;}
  }
  .price-table{
    margin-top:24px;word-break:break-all;
    table{
      width:100%;margin-top:-1px;
      td{
        text-align:center;vertical-align:middle;border:1px solid #eee;
        &.is-disabled{
          background-color:#fbfbfb;
          .day{color:#999;}
          .value{color:#bbb;}
        }
      }
      .td-item{
        position:relative;height:33px;padding:2px 0;overflow:hidden;border-top:1px solid #eee;
        &:first-child{border-top:none;}
        &:after{display:inline-block;height:100%;vertical-align:middle;content:'';}
        > span{display:inline-block;line-height:14px;vertical-align:middle;}
      }
      .is-edit{
        &:hover{
          .day{
            > span{display:none}
            .icon{display:inline-block;}
          }
        }
      }
      .th{color:#252525;font-weight:bold;background-color:#fbfbfb;width: 25px;}
      .value{font-size:12px;}
      .day{color:#252525;font-weight:bold;background-color:#fbfbfb;}
      .label{padding-left:10px;padding-right:10px;text-align:right;color:#252525;font-weight:bold;}
    }
    .i-edit{display:none;width:16px;height:16px;margin-right:8px;vertical-align:middle;cursor:pointer;background-image:url(../../../../assets/images/icon/pen.png);}
    .i-ashcan{display:none;width:16px;height:16px;vertical-align:middle;cursor:pointer;background-image:url(../../../../assets/images/icon/ashcan.png);}
    .i-unlock{display:none;width:16px;height:16px;vertical-align:middle;cursor:pointer;background-image:url(../../../../assets/images/icon/unlock.png);}
  }
</style>
