<template>
  <modal :title="$t('BatchClose')" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="batch-close">
      <div class="tip">{{$t('ClickOnTheDateInTheCalendarToAddATourGroupThatNeedsToBeclosed')}}</div>
      <calendars :selecteds="priceDates" v-model="selectedList" :status="priceDateStatus"/>
      <div class="selected-list" v-if="selectedList.length">
        <div class="title">{{$t('SelectedDate')}}：</div>
        <div class="cl">
          <div class="item"
               v-for="(item,index) of selectedList"
               :key="item">
            {{item}}
            <a class="btn-del" href="javascript:;" @click="deleteItem(index)">
              <img src="../../../../assets/images/icon/close-circle.png"/>
            </a>
          </div>
        </div>
      </div>
      <div class="actions">
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('SaveAndPublish')}}</button>
      </div>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../../../components/Modal';
  import Calendars from '../../../../components/Calendars';
  import {closeProductPrice} from '../../../../api/admin-product';

  export default {
    name: 'BatchCloseModal',
    props: {
      productId: String,
      lineId: String,
      priceDates: Array,
      priceDateStatus: Object
    },
    data() {
      return {
        isShow: false,
        selectedList: []
      };
    },
    components: {Modal, Calendars},
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      deleteItem(index) {
        this.selectedList.splice(index, 1);
      },
      onSubmit() {
        const data = this.selectedList;

        if (!data.length) {
          this.$message.warning('请选择日期');
          return false;
        }

        closeProductPrice(this.lineId, data).then(res => {
          this.$message.success(this.$t('SaveSuccessfully'));
          this.$emit('refresh-price');
          Object.assign(this.$data, this.$options.data());
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .batch-close{
    padding:0 30px 40px;text-align:left;
    .tip{margin-top:30px;margin-bottom:16px;}
  }
  .selected-list{
    user-select:none;
    .title{margin-top:30px;padding-bottom:6px;font-size:16px;}
    .item{
      position:relative;float:left;width:130px;margin-top:10px;margin-right:10px;line-height:42px;text-align:center;color:#999;font-size:18px;border:1px dashed #ccc;border-radius:4px;
      &:hover{
        .btn-del{display:block;}
      }
    }
    .btn-del{display:none;position:absolute;top:10px;right:2px;line-height:1;}
  }
</style>
