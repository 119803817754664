<template>
  <div class="title-code">
    {{$t('ProductNumber')}}：{{code||productCode}}&#x3000;{{$t('ProductName')}}：{{title||productTitle}}
  </div>
</template>
<script>
  import {getProductInfo} from '../../../../api/admin-product';

  export default {
    name: 'TitleCode',
    props: ['language', 'productId', 'productTitle', 'productCode'],
    data() {
      return {
        title: '',
        code: ''
      };
    },
    created() {
      this.getTitle();
    },
    methods: {
      getTitle() {
        if (!this.productTitle) {
          getProductInfo(this.language, this.productId).then(res => {
            this.title = res.value.title;
            this.code = res.value.productCode;
          });
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .title-code{margin-top:30px;font-size:24px;}
</style>
