<template>
  <div class="custom-item">
    <div class="name cl" v-if="false">
      <div class="label">{{$t('CustomColumn')}}：</div>
      <div class="ipt-wrap">
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('EnterTheNameOfTheColumn')"
               v-model.trim="programName"/>
      </div>
    </div>
    <div class="self-funded">
<!--      <div>-->
<!--        <froala :config="froalaConfig" v-model="content"/>-->
<!--      </div>-->
<!--      <div  id="editor"></div>-->
      <editorElem v-model="content"/>
      <div class="actions">
        <button class="button button-primary" type="button" :disabled="!content" @click="onSubmit">
          {{$t('Save')}}
        </button>
        <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import editor from '../../../../mixins/editor';
  import {saveProductCommon, getProductCommonDetail} from '../../../../api/admin-product';
  import editorElem from '../../../../components/EditorBar';
  export default {
    name: 'CustomItem',
    props: {
      productId: String,
      language: String,
      lineId: String,
      item: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        currentId: '',
        content: '',
        programLabel: '',
        programName: '',
        isCustomize: '',
        oldContent: ''
      };
    },
    components: {editorElem},
    mixins: [editor],
    created() {
      this.programLabel = this.item.programLabel || '';
      this.programName = this.item.programName || '';
      this.isCustomize = this.item.isCustomize || '';
      this.getDetail();
    },
    destroyed() {
      if (this.content !== this.oldContent) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      // 获取详情
      getDetail() {
        getProductCommonDetail(this.language, this.lineId, this.programLabel).then(res => {
          const value = res.value || {};
          this.currentId = value.currentId || '';
          this.content = value.content || '';
          this.oldContent = this.content;
        });
      },
      // 储存
      onSubmit(callback, isForcedSave) {
        const data = {
          productId: this.productId,
          lineId: this.lineId,
          languageType: this.language,
          currentId: this.currentId,
          programLabel: this.programLabel,
          programName: this.programName,
          isCustomize: this.isCustomize,
          title: this.programName,
          content: this.content
        };

        saveProductCommon(data).then(res => {
          this.oldContent = this.content;
          !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
          if (!this.currentId) {
            this.currentId = res.value.currentId;
          }
          if (typeof callback === 'function') {
            callback();
          }
        });
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .custom-item{
    .name{margin-bottom:20px;}
    .label{float:left;line-height:62px;font-size:16px;}
    .ipt-wrap{margin-left:96px;}
    .ipt{
      width:100%;height:60px;padding:0 20px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
  }
  .self-funded{
    .ipt{
      width:100%;height:60px;padding:0 20px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .el-textarea .el-textarea__inner{height:342px;padding:20px;}
  }
</style>
