<template>
  <div class="calendars-wrap cl">
    <div class="fl calendars cl">
      <div class="calendar-item">
        <div class="hd cl">
          <button class="fl el-icon-arrow-left" type="button" @click="setMonth(-1)"></button>
          <div>{{year}} - {{month}}</div>
        </div>
        <div class="bd">
          <table>
            <thead>
            <tr>
              <th v-for="(value,name) of $t('el').datepicker.weeks" :key="name">
                <div>{{value}}</div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(week,index) of monthData" :key="index">
              <td v-for="item of week"
                  :class="{disabled:item.ts<timestamp&&!item.today || statusDate(item.dateStr)}"
                  :key="item.ts">
                <div v-if="item.curMonth===0"
                     :class="{active:selectedList.includes(item.dateStr), 'is-select':selecteds.includes(item.dateStr)}"
                     @click="selectDate(item)">
                  {{item.day}}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="calendar-item">
        <div class="hd cl">
          <button class="fr el-icon-arrow-right" type="button" @click="setMonth(1)"></button>
          <div>{{nextYear}} - {{nextMonth}}</div>
        </div>
        <div class="bd">
          <table>
            <thead>
            <tr>
              <th v-for="(value,name) of $t('el').datepicker.weeks" :key="name">
                <div>{{value}}</div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(week,index) of nextMonthData" :key="index">
              <td v-for="item of week"
                  :class="{disabled:item.ts<timestamp&&!item.today || statusDate(item.dateStr)}"
                  :key="item.ts">
                <div v-if="item.curMonth===0"
                     :class="{active:selectedList.includes(item.dateStr), 'is-select':selecteds.includes(item.dateStr)}"
                     @click="selectDate(item)">
                  {{item.day}}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import CalendarHelper from 'calendar-helper/index';

  export default {
    name: 'Calendars',
    props: {
      value: Array,
      selecteds: {
        type: Array,
        default: () => []
      },
      status: Object
    },
    data() {
      return {
        monthData: [],
        nextMonthData: [],
        year: '',
        month: '',
        selectedList: [...this.value],
        timestamp: new Date().getTime()
      };
    },
    computed: {
      nextYear: function () {
        return this.year + (this.month >= 12 ? 1 : 0);
      },
      nextMonth: function () {
        const month = this.month + 1;
        return month > 12 ? 1 : month;
      }
    },
    created() {
      const now = new Date();
      this.year = now.getFullYear();
      this.month = now.getMonth() + 1;
      this.getMonthData();
    },
    methods: {
      // 上一个月/下一个月
      setMonth(val) {
        let month = ~~this.month + val;
        if (month > 12) {
          this.year += 1;
          month = 1;
        } else if (month === 0) {
          this.year -= 1;
          month = 12;
        }
        this.month = month;
        this.getMonthData();
      },
      // 获取月数据
      getMonthData() {
        const date = new Date(this.year, this.month - 1, 1);
        const nextDate = new Date(this.nextYear, this.nextMonth - 1, 1);
        this.monthData = CalendarHelper.getMonthData(date, new Date());
        this.nextMonthData = CalendarHelper.getMonthData(nextDate, new Date());
      },
      selectDate(data) {
        if (this.selectedList.includes(data.dateStr)) {
          this.selectedList.splice(this.selectedList.indexOf(data.dateStr), 1);
        } else {
          this.selectedList.push(data.dateStr);
        }
        this.$emit('input', [...this.selectedList]);
      },
      statusDate(data) {
        return this.status[data] === 'close';
      }
    },
    watch: {
      value(value) {
        this.selectedList = [...value];
      }
    }
  };
</script>
<style scoped lang="less">
  .calendars{border-radius:4px;box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.15);}
  .calendar-item{
    float:left;
    .hd{
      line-height:38px;text-align:center;border-bottom:1px solid #f3f3f3;
      > div{padding:0 50px;color:#000;}
    }
    .bd{padding:16px;user-select:none;}
    table{
      color:#606266;font-size:14px;
      th,
      td{
        padding:2px;
        > div{width:24px;line-height:24px;text-align:center;}
      }
      th{color:#000;font-weight:normal;}
      td{
        > div{
          cursor:pointer;
          &.is-select{color:#ff6f61;}
          &:hover{background-color:#ffe2df;}
          &.active{color:#fff;background-color:#ff6f61;border-radius:2px;}
        }
        &.disabled{
          position:relative;
          &:after{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;cursor:not-allowed;content:'';}
          > div{color:#c0c4cc;}
        }
      }
    }
    .el-icon-arrow-left,
    .el-icon-arrow-right{
      margin:6px 4px 0;padding:4px;color:#999;font-size:16px;border:none;
      &:hover{color:#ff6f61;}
    }
  }
</style>
