<template>
  <el-dialog :title="$t('AddPicture')" width="700px" :close-on-click-modal="false" :visible.sync="visible">
    <div class="img-list">
      <multimedia-item show-delete-button
                       v-for="(item,index) of list"
                       :index="index"
                       :item="item"
                       @delete-picture="deletePicture"
                       :big-picture-id="currentId"
                       @update-bgid="updateCurrentId"
                       :key="index"/>
      <div class="txt-cpic">
        <a class="btn-add" href="javascript:;" @click="addImg">
          <img class="vam" src="../../../../assets/images/icon/plus-circle-red.png">
          <span class="vam"> {{$t('AddPicture')}}</span>
        </a>
      </div>
    </div>
    <div class="actions">
      <button class="button m-r" type="button" @click="visible=false">{{$t('Cancel')}}</button>
      <el-button class="button button-primary"
                 :loading="isSubmiting"
                 :disabled="disabledSubmit"
                 @click="onSubmit">{{$t('Save')}}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import MultimediaItem from './MultimediaItem';
import {saveProductMediaList} from '../../../../api/admin-product';

export default {
    name: 'MultimediaAddDialog',
    components: {MultimediaItem},
    props: {
      productId: String,
      language: String,
      show: Boolean
    },
    data() {
      return {
        visible: false,
        list: [],
        isSubmiting: false,
        currentId: ''
      };
    },
    computed: {
      disabledSubmit: function () {
        let isDisabled = false;
        for (let item of this.list) {
          // if (!item.title || !item.introduction || !item.photoUrl) {
          if (!item.photoUrl) {
            isDisabled = true;
            break;
          }
        }
        return isDisabled;
      }
    },
    methods: {
      addImg() {
        // if (this.list.length >= 5) {
        //   this.$message.warning(this.$t('UpToFiveAtATime'));
        //   return false;
        // }
        this.list.push({
          mediaId: new Date().getTime() + '',
          productId: this.productId,
          languageType: this.language,
          title: '',
          introduction: '',
          photoUrl: ''
        });
        // 滚动到底部
        setTimeout(() => {
          let container = this.$el.querySelector('.img-list');
          container.scrollTop = container.scrollHeight;
        }, 100);
      },
      deletePicture(index) {
        this.list.splice(index, 1);
        if (!this.list.length) {
          this.$nextTick(() => {
            this.addImg();
          });
        }
      },
      onSubmit() {
        const data = this.list.map(item => {
          const itemData = {...item};
          itemData.isDefault = itemData.mediaId === this.currentId ? 'Y' : 'N';
          itemData.mediaId = '';
          return itemData;
        });
        this.isSubmiting = true;
        saveProductMediaList(data, 'save').then(res => {
          this.isSubmiting = false;
          this.$emit('update:show', false);
          this.$emit('reload-list');
          this.$message.success(this.$t('SaveSuccessfully'));
        }).catch(() => {
          this.isSubmiting = false;
        });
      },
      updateCurrentId(value) {
        this.currentId = value;
      }
    },
    watch: {
      show: function (value) {
        this.list = [];
        this.isSubmiting = false;
        this.addImg();
        this.visible = value;
      },
      visible: function (value) {
        this.$emit('update:show', value);
      }
    }
  };
</script>
<style scoped lang="less">
  .img-list{
    max-height:50vh;margin:0 -20px;padding:0 20px;overflow-y:auto;
    .btn-add{display:inline-block;line-height:1;color:#ff6f61;}
  }
  .txt-cpic{
    text-align: center;
    position: sticky;
    bottom: 0;
    background: white;
    padding: 10px 0;
  }
</style>
