<template>
  <div class="flight-wrap" v-if="isShow">
    <title-code :language="language" :product-id="productId" :product-title="productTitle" :product-code="productCode"/>
    <div class="tabs-nav cl">
      <div class="item"
           v-for="(item,index) of line"
           :class="{active:index===lineIndex}"
           :key="item.id"
           @click="lineIndex=index">
        <div class="letter">{{item.lineName}}</div>
        <div class="line-title">{{item.lineTitle}}</div>
      </div>
    </div>
    <div class="actions txt-l">
      <el-dropdown class="btn-edit" trigger="click" @command="batchEdit">
        <button class="button" type="button" :disabled="!selectedList.length">{{$t('BatchIncrease')}}</button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="lang of languageOption"
                            :command="{language:lang.value,type:'add'}"
                            :key="lang.value">
            {{lang.name}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="btn-edit" trigger="click" @command="batchEdit">
        <button class="button" type="button" :disabled="!selectedList.length">{{$t('BatchEdit')}}</button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="lang of languageOption"
                            :command="{language:lang.value,type:'edit'}"
                            :key="lang.value">
            {{lang.name}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="table border">
      <table>
        <thead>
        <tr>
          <th>
            <checkbox :value="isSelectedAll" @click.native="selectAll"/>
          </th>
          <th>
            <el-dropdown @command="changeFlag">
              <a href="javascript:;">{{$t('Outing day')}} <i class="el-icon-caret-bottom"></i></a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :class="{selected:isFlag===''}" command="">
                  {{$t('Outing day')}}({{$t('All')}})
                </el-dropdown-item>
                <el-dropdown-item :class="{selected:isFlag==='N'}" command="N">
                  {{$t('Outing day')}}({{$t('No flight')}})
                </el-dropdown-item>
                <el-dropdown-item :class="{selected:isFlag==='Y'}" command="Y">
                  {{$t('Outing day')}}({{$t('Existing flight')}})
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </th>
          <th>
            <el-dropdown :hide-on-click="false" @command="changeWeek">
              <a href="javascript:;">{{$t('Xingqi')}} <i class="el-icon-caret-bottom"></i></a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) of $t('week')"
                                  :class="{selected:weeks.includes(index)}"
                                  :command="index"
                                  :key="index">
                  {{item}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </th>
          <th>{{$t('Customize')}}</th>
          <th>{{$t('Detailed')}}</th>
          <th>{{$t('Flight')}}</th>
          <th>{{$t('Departure')}}</th>
          <th>{{$t('Destination')}}</th>
          <th>{{$t('Airline')}}</th>
          <th>{{$t('Flight Number')}}</th>
          <th>{{$t('Flight date')}}</th>
          <th>{{$t('Departure time')}}</th>
          <th>{{$t('Arrival time')}}</th>
          <th width="60">{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="item of list">
          <template v-if="item.flightDetails.length">
            <tr v-for="(fItem,index) of item.flightDetails" :key="fItem.outsetDate">
              <template v-if="index===0">
                <td :rowspan="item.flightDetails.length">
                  <checkbox :value="item.isSelected" @click.native="item.isSelected=!item.isSelected"/>
                </td>
                <td class="nowrap" :rowspan="item.flightDetails.length">{{item.departureDate}}</td>
                <td :rowspan="item.flightDetails.length">{{$t('week')[item.week]}}</td>
                <td class="tick" :rowspan="item.flightDetails.length"></td>
                <td class="tick" :rowspan="item.flightDetails.length">
                  <img src="../../../../assets/images/tick.png"/>
                </td>
              </template>
              <td class="nowrap">{{$t('No.Schedule',[fItem.stage])}}</td>
              <td>{{fItem.departure}}</td>
              <td>{{fItem.destination}}</td>
              <td>{{fItem.airline}}</td>
              <td>{{fItem.airlineNumber}}</td>
              <td>{{fItem.departureDate|flightDate(fItem.fewDay)}}</td>
              <td>{{fItem.outsetTime}}</td>
              <td>{{fItem.arrivalsTime}}{{fItem.addDay?'+1':''}}</td>
              <td v-if="index===0" :rowspan="item.flightDetails.length">
                <template v-if="item.isFlag==='Y'">
                  <el-dropdown class="btn-edit" trigger="click" @command="toEdit">
                    <div><img class="vam" src="../../../../assets/images/icon/pen.png"/></div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="lang of languageOption"
                                        :command="{language:lang.value,item}"
                                        :key="lang.value">
                        {{lang.name}}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <a class="btn-item" @click="clearProductFlight(item.departureDate)">
                    <img src="../../../../assets/images/icon/ashcan.png"/>
                  </a>
                </template>
                <a class="btn-item" @click="toEdit({language,item})" v-else>
                  <img src="../../../../assets/images/icon/plus-circle-red.png"/>
                </a>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr :key="item.departureDate">
              <td>
                <checkbox :value="item.isSelected" @click.native="item.isSelected=!item.isSelected"/>
              </td>
              <td class="nowrap">{{item.departureDate}}</td>
              <td>{{$t('week')[item.week]}}</td>
              <td class="tick"><img src="../../../../assets/images/tick.png" v-if="item.isCustomize==='Y'"/></td>
              <td class="tick"></td>
              <td colspan="8"></td>
              <td>
                <template v-if="item.isFlag==='Y'">
                  <el-dropdown class="btn-edit" trigger="click" @command="toEdit">
                    <div><img class="vam" src="../../../../assets/images/icon/pen.png"/></div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="lang of languageOption"
                                        :command="{language:lang.value,item}"
                                        :key="lang.value">
                        {{lang.name}}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <a class="btn-item" @click="clearProductFlight(item.departureDate)">
                    <img src="../../../../assets/images/icon/ashcan.png"/>
                  </a>
                </template>
                <a class="btn-item" @click="toEdit({language,item})" v-else>
                  <img src="../../../../assets/images/icon/plus-circle-red.png"/>
                </a>
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
      <empty v-if="isEmpty" :title="$t('NoData')"/>
    </div>
    <edit-flight-modal :product-id="productId"
                       :language="language"
                       :line-id="lineId"
                       :max-day="maxDay"
                       @reload-list="getProductFlightLineList"
                       ref="editFlightModal"/>
  </div>
</template>
<script>
  import Empty from '../../../../components/Empty';
  import Checkbox from '../../../../components/Checkbox';
  import TitleCode from './TitleCode';
  import EditFlightModal from './EditFlightModal';
  import {languageOptions} from '../../../../utils/language';
  import {getProductLine, getProductFlightLineList, clearProductFlight} from '../../../../api/admin-product';

  export default {
    name: 'FlightInformation',
    props: {
      productId: String,
      language: String,
      productCode: String,
      productTitle: String
    },
    data() {
      return {
        isShow: false,
        line: [],
        lineIndex: 0,
        list: [],
        totalPages: 1,
        isEmpty: false,
        languageOption: languageOptions,
        isFlag: '', // Y为已添加，N为未添加，空为全部
        weeks: []
      };
    },
    computed: {
      lineId: function () {
        return this.line.length ? this.line[this.lineIndex].lineId : '';
      },
      selectedList: function () {
        return this.list.filter(item => item.isSelected);
      },
      isSelectedAll: function () {
        if (!this.list.length) {
          return false;
        } else {
          return this.list.length === this.selectedList.length;
        }
      },
      maxDay() {
        if (this.lineId && this.line.length) {
          const currentLine = this.line.find(item => item.lineId === this.lineId) || {};
          return currentLine.days || currentLine.lates || 1;
        }
        return 1;
      }
    },
    components: {TitleCode, EditFlightModal, Empty, Checkbox},
    created() {
      this.getProductLine();
    },
    methods: {
      // 获取产品路线
      getProductLine() {
        getProductLine(this.language, this.productId).then(res => {
          this.line = res.value || [];
          this.isShow = true;
        });
      },
      // 切换航班状态
      changeFlag(command, instance) {
        this.isFlag = command;
        this.getProductFlightLineList();
      },
      // 切换星期几
      changeWeek(command, instance) {
        if (this.weeks.includes(command)) {
          this.weeks.splice(this.weeks.indexOf(command), 1);
        } else {
          this.weeks.push(command);
        }
        this.getProductFlightLineList();
      },
      // 全选/取消全选
      selectAll() {
        const isSelected = !this.isSelectedAll;
        this.list = this.list.map(item => {
          item.isSelected = isSelected;
          return item;
        });
      },
      // 获取产品路线航班资料列表
      getProductFlightLineList() {
        const data = {lineId: this.lineId, isFlag: this.isFlag, weeks: this.weeks, languageType: this.language};
        getProductFlightLineList(data).then(res => {
          this.list = (res.value || []).map(item => {
            item.isSelected = false;
            item.flightDetails = item.flightDetails || [];
            return item;
          });
          this.isEmpty = !this.list.length;
        });
      },
      // 清除产品路线航班资料
      clearProductFlight(departureDate) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              clearProductFlight(this.lineId, departureDate).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.getProductFlightLineList();
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 单个修改
      toEdit(command) {
        const language = command.language;
        const departureDates = [command.item.departureDate];
        this.$refs.editFlightModal.show(departureDates, language);
      },
      // 批量新增、修改
      batchEdit(command) {
        const language = command.language;
        const type = command.type;
        const departureDate = this.selectedList.map(item => item.departureDate);
        this.$refs.editFlightModal.show(departureDate, language, type);
      }
    },
    watch: {
      lineId: function (value) {
        this.getProductFlightLineList();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../../assets/style/table";
  .flight-wrap{
    .actions.txt-l{
      margin-top:24px;text-align:left;
      > button{min-width:130px;margin-right:8px;}
    }
    .btn-edit{margin-right:8px;vertical-align:middle;cursor:pointer;}
    .table.border{
      td{vertical-align:top;}
      .tick{padding-top:14px;line-height:1;text-align:center;}
    }
  }
  .tabs-nav{
    margin-top:22px;padding-bottom:20px;border-bottom:1px dashed #ccc;
    .item{
      float:left;width:132px;height:44px;margin-top:8px;margin-right:8px;padding:0 20px;padding:4px 10px;line-height:17px;text-align:center;color:#999;font-size:18px;border:1px dashed #ccc;border-radius:4px;cursor:pointer;
      &.active{color:#ff6f61;border-color:#ff6f61;pointer-events:none;}
      &:hover{border-color:#ff6f61;}
    }
    .letter{font-size:18px;}
    .line-title{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px;}
  }
</style>
